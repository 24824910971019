import React, { useState, useContext } from 'react';
import styles from '../scss/components/_BurgerMenu.module.scss';
import Navbar from "../components/Navbar"
import messiBkg from "../images/messi644/BackgroundIlustracionMenu.png";
import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import twitter from "../images/icons/twitter.svg"
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import { Link } from 'gatsby';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
var Scroll = require('react-scroll');
var LinkScroll = Scroll.Link;

const BurgerMenu = ({ props, handleClick, toggle, handleToggle, setUser }) => {
    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    // const [toggle, setToggle] = useState(false);
    const [positions, setPositions] = useState(false);

    // const handleToggle = () => { 
    //     if (toggle) {
    //         setToggle(false)
    //     } else {
    //         setToggle(true)
    //     }
    // }

    const handlePositions = () => {
        if (positions) {
            setPositions(false)
        } else {
            setPositions(true)
        }
    }

    const logOut = () => {
        Cookies.remove('bud')
        setUser(false)
        dispatch({ type: "SET_USER", user: { logged: false } })
        props.navigate('/messi644/home')
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img onClick={() => { props.navigate('../') }} src={budweiser} className={styles.imgLogo} />
                <Navbar props={props} logOut={logOut}/>
            </div>
            <style>{'height: .4px !important; background-color: #faf !important;'}</style>
            <div className={toggle ? styles.container : styles.containerNull} >
                <nav className={toggle ? styles.navbar : styles.changeNavbar}>
                    <img src={messiBkg} className={styles.imgBackground} />
                    <ul className={styles.navList} style={{ marginTop: '7%' }}>
                        <li className={styles.navItem}>
                            <Link to='/messi644/home' onClick={() => {
                                handleToggle()
                            }} className={styles.navLink}>INICIO</Link>
                        </li>
                    </ul>
                    <ul className={styles.navList}>
                        {state.user ? (state.user.logged ? ( 
                            <li className={styles.navItem}>
                                <Link to='/messi644/micuenta' onClick={() => {
                                    handleToggle()
                                }} className={styles.navLink}>MI CUENTA</Link>
                            </li>
                        ) : (
                                <li className={styles.navItem}>
                                    <Link to='/messi644/login' onClick={() => {
                                        handleToggle()
                                    }} className={styles.navLink}>MI CUENTA</Link>
                                </li>
                            )) : (<li className={styles.navItem}>
                                <Link to='/messi644/login' onClick={() => {
                                    handleToggle()
                                }} className={styles.navLink}>MI CUENTA</Link>
                            </li>)}

                    </ul>
                    <ul className={styles.navList}>
                        <li className={styles.navItem}>
                            <span href="#"
                                onClick={handlePositions}
                                className={styles.navLink}>POSICIONES</span>
                        </li>
                    </ul>
                    {positions ? (
                        <div className={styles.positions}>
                            <LinkScroll
                                to="rankGeneral"
                                spy={true}
                                smooth={true}
                                duration={500}
                                name='general'
                                onClick={(e) => {
                                    handleToggle()
                                    handleClick(e)
                                }}
                            >RANKING GENERAL
                            </LinkScroll>
                            {state.user.logged ? (
                                <LinkScroll
                                    to="rankGeneral"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    name="torneo"
                                    onClick={(e) => {
                                        handleToggle()
                                        handleClick(e)
                                    }}
                                >MIS TORNEOS
                                </LinkScroll>

                            ) : (
                                    <LinkScroll
                                        to="rankGeneral"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        onClick={() => {
                                            handleToggle()
                                        }}
                                    >MIS TORNEOS
                                    </LinkScroll>
                                )}
                        </div>
                    ) : (
                            <div style={{ marginTop: '50px' }}></div>
                        )}
                    {state.user.logged ? (
                        <div onClick={() => props.navigate('tournament')} className={styles.btnFirst}>Crear un torneo</div>
                    ) : (
                            <div onClick={() => props.navigate("login")} className={styles.btnFirst}>Crear un torneo</div>

                        )}
                    {state.user.logged ? (
                        <div onClick={logOut} className={styles.btnSecond}>Cerrar sesión</div>
                    ) : (
                            <div onClick={() => props.navigate("login")} className={styles.btnSecond}>Iniciar sesión</div>

                        )}

                    <div className={styles.recover}>

                        <a onClick={() => {
                            handleToggle()
                            props.navigate('/messi644/register')
                        }}>¿No tenés cuenta? <span>Registrate</span></a>

                        <div>
                            <a target="_blank" href="https://www.instagram.com/budargentina/"><img src={instagram} alt="Instagram" /></a>
                            <a target="_blank" href="https://www.facebook.com/BudweiserArgentina/"><img src={facebook} alt="Facebook" /></a>
                            <a target="_blank" href="https://twitter.com/budargentina"><img src={twitter} alt="Twitter" /></a>
                        </div>
                    </div>

                </nav>
            </div>
        </>
    )
}

export default BurgerMenu;