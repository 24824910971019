import React, { useContext, useState } from "react"
import styles from "../scss/components/_Register.module.scss"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import Cookies from "js-cookie"
import close from "../images/icons/close.svg"
import budweiser from "../images/messi644/logoBudweiser-01.svg"
const short = require("short-uuid")

const Register = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const [email, setEmail] = useState(false)
  const [password, setPassword] = useState(false)
  const [passwordConf, setPasswordConf] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [provincia, setProvincia] = useState(false)
  const [user, setUser] = useState(false)
  const [error, setError] = useState(false)
  const [errorValidation, setErrorValidation] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = props.location.state

  const register = e => {
    setLoading(true)
    e.preventDefault()
    if (password.length < 6) {
      setLoading(false)
      return setErrorValidation({
        target: "password",
        msg: "*La contraseña debe contenera al menos 6 caracteres!",
      })
    }
    if (password != passwordConf) {
      setLoading(false)
      return setErrorValidation({
        target: "passwordConf",
        msg: "*Las contraseñas no coinciden!",
      })
    } else {
      let idUser = short.generate()
      setErrorValidation({})
      Cookies.remove("bud")
      let td = Cookies.get("_td")
      fetch("https://wabudweiser-prd.azurewebsites.net/users/register", {
        method: "POST",
        body: JSON.stringify({
          idUser,
          fullName,
          email,
          password,
          provincia,
          td,
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async response => {
        if (response.status === 201) {
          let data = await response.json()
          setUser(data)
          dispatch({
            type: "SET_USER",
            user: { logged: true, user: { idUser: idUser } },
          })
          Cookies.set("bud", idUser, { expires: 1 })
          window.dataLayer.push({
            event: "trackEvent",
            eventCategory: "register",
            eventAction: "click",
            eventLabel: "registrarse",
            state: provincia,
            eventValue: td,
          })
          if (location && location.survey) {
            let arr = location.survey[1]
            let nroGol = location.survey[0]
            fetch("https://wabudweiser-prd.azurewebsites.net/surveys", {
              method: "POST",
              body: JSON.stringify(arr),
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                id: idUser,
                goal: nroGol,
              },
            }).then(async response => {
              if (response.ok) {
                let res = await response.json()
                if (nroGol == 644) props.navigate("/messi644/thankyou")
                else props.navigate("/messi644/micuenta")
              } else {
                console.error("Error guardando los datos. \n", response)
              }
            })
          } else if (location && location.tournament) {
            let userT = { idTournament: location.tournament, idUser: idUser }
            fetch(
              "https://wabudweiser-prd.azurewebsites.net/tournaments/users",
              {
                method: "POST",
                body: JSON.stringify(userT),
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            ).then(async response => {
              if (response.ok) {
                props.navigate("/messi644/micuenta", {
                  state: { mitorneo: true },
                })
              } else {
                let msj = await response.json()
                console.log(msj)
                console.error("Error uniendose al torneo. \n", response)
              }
            })
          } else {
            props.navigate("/messi644/micuenta")
          }
        } else {
          let msj = await response.json()
          setLoading(false)
          console.log(msj)
          setError(msj)
          console.error("Error guardando los datos. \n", response)
        }
      })
    }
  }

  return (
    <div>
      <style>
        {
          "body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}"
        }
      </style>
      {/* <img src={bottle} className={styles.imgBackground} /> */}
      <div className={styles.navCont}>
        <img
          onClick={() => {
            props.navigate("/messi644/home")
          }}
          src={budweiser}
          className={styles.imgLogo}
        />
        <img
          onClick={() => window.history.back()}
          src={close}
          className={styles.closeIcon}
        />
      </div>
      <form onSubmit={register}>
        <div className={styles.formContainer}>
          <h3>Registrate para participar</h3>
          {errorValidation.target == "fullName" ? (
            <p className={styles.mensaje}>{errorValidation.msg}</p>
          ) : null}
          <input
            type="text"
            placeholder="Nombre y apellido"
            onChange={e => setFullName(e.target.value)}
            required
          ></input>
          {errorValidation.target == "email" ? (
            <p className={styles.mensaje}>{errorValidation.msg}</p>
          ) : null}
          <input
            type="email"
            placeholder="email"
            onChange={e => setEmail(e.target.value)}
            required
          ></input>
          {errorValidation.target == "password" ? (
            <p className={styles.mensaje}>{errorValidation.msg}</p>
          ) : null}
          <input
            type="password"
            placeholder="contraseña"
            onChange={e => setPassword(e.target.value)}
            required
          ></input>
          {errorValidation.target == "passwordConf" ? (
            <p className={styles.mensaje}>{errorValidation.msg}</p>
          ) : null}
          <input
            type="password"
            placeholder="confirmar contraseña"
            onChange={e => setPasswordConf(e.target.value)}
            required
          ></input>
          {errorValidation.target == "provincia" ? (
            <p className={styles.mensaje}>{errorValidation.msg}</p>
          ) : null}
          {/* <input type='text' placeholder="Provincia" onChange={e => setClub(e.target.value)} required></input> */}
          <select
            required
            onChange={e => setProvincia(e.target.value)}
            id="first-choice"
            // style={{color: 'black',background:'transparent'}}
          >
            <option disabled selected value="">
              Provincia
            </option>
            <option value="CABA">CABA</option>
            <option value="Buenos Aires">Buenos Aires</option>
            <option value="Catamarca">Catamarca</option>
            <option value="Chaco">Chaco</option>
            <option value="Chubut">Chubut</option>
            <option value="Córdoba">Córdoba</option>
            <option value="Corrientes">Corrientes</option>
            <option value="Entre Ríos">Entre Ríos</option>
            <option value="Formosa">Formosa</option>
            <option value="Jujuy">Jujuy</option>
            <option value="La Pampa">La Pampa</option>
            <option value="La Rioja">La Rioja</option>
            <option value="Mendoza">Mendoza</option>
            <option value="Misiones">Misiones</option>
            <option value="Neuquén">Neuquén</option>
            <option value="Río Negro">Río Negro</option>
            <option value="Salta">Salta</option>
            <option value="San Juan">San Juan</option>
            <option value="San Luis">San Luis</option>
            <option value="Santa Cruz">Santa Cruz</option>
            <option value="Santa Fe">Santa Fe</option>
            <option value="Santiago del Estero">Santiago del Estero</option>
            <option value="Tierra del Fuego">Tierra del Fuego</option>
            <option value="Tucumán">Tucumán</option>
          </select>
          <div className={styles.check}>
            <input type="checkbox" required />
            <div>
              <p>
                He leído y acepto{" "}
                <a target="_blank" href="/TerminosyCondiciones.pdf">
                  términos y condiciones
                </a>
                ,{" "}
                <a target="_blank" href="/PoliticadePrivacidad.pdf">
                  políticas de privacidad
                </a>{" "}
                y activaciones de marketing
              </p>
            </div>
          </div>
          <div className={styles.btnCont}>
            <div className={styles.error}>{error ? <p>{error}</p> : null}</div>
            {loading ? (
              <img
                style={{
                  width: "15%",
                  height: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                src={require("../images/messi644/spinner.gif")}
              />
            ) : (
              <button className={styles.btnFirst} type="submit">
                Registrarme
              </button>
            )}
            <div className={styles.recover}>
              <p>¿Ya tenés cuenta?</p>
              <a
                onClick={() => {
                  if (location && location.survey)
                    props.navigate("/messi644/login", {
                      state: { survey: location.survey },
                    })
                  else props.navigate("/messi644/login")
                }}
              >
                Iniciá sesión
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Register
