import React, { useEffect, useState } from 'react';
import styles from '../scss/components/_MessiFooter.module.scss';
import messiKing from "../images/messi644/Trazado124.svg"
import kingFootball from "../images/messi644/Grupo63.svg"

const MessiFooter = () => {
    return (
        <div> 
            <div className={styles.footer}>
                <img src={messiKing} />
                <img src={kingFootball} />
                <div className={styles.footerLinks}>
                    <a href="mailto:budweiser.ar@gmail.com">Contacto</a>
                    <p>|</p>
                    <a target="_blank" href="/PoliticadePrivacidad.pdf">Política de privacidad </a>
                    <p>|</p>
                    <a target="_blank" href="/TerminosyCondiciones.pdf">Términos y condiciones</a>
                </div>
                <div className={styles.footerLinkUltimo}>
                    <a target="_blank" href="/BasesyCondiciones644.pdf">Bases y condiciones</a>
                    <p>|</p>
                    <a href='https://www.cerveceriaymalteriaquilmes.com'>Información Oficial de la compañía</a>
                </div>
                <p>BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.</p>
                <p>NO COMPARTA EL CONTENIDO CON MENORES.</p>
                <p>@2020 Quilmes - Buenos Aires - Argentina. Todos los derechos reservados.</p>
            </div>
        </div>
    )
}

export default MessiFooter;