import React, { useContext, useState } from 'react';
import styles from '../scss/components/_Register.module.scss';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import close from "../images/icons/close.svg"
import budweiser from "../images/messi644/logoBudweiser-01.svg"

const Forgot = (props) => {
    const dispatch = useContext(GlobalDispatchContext)
    const [email, setEmail] = useState(false);
    const [message, setMessage] = useState(false)
    const [error, setError] = useState(false)
    const [errorValidation, setErrorValidation] = useState(false)
    const location = props.location.state

    const check = (e) => {
        e.preventDefault()

        setErrorValidation({})
        Cookies.remove('bud')
        fetch('https://wabudweiser-prd.azurewebsites.net/forgotpassword', {
            method: 'POST',
            body: JSON.stringify({ email }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    setMessage("Te enviamos un correo a tu email. Seguí los pasos para cambiar tu contraseña.")
                } else {
                    let msj = await response.json()
                    console.log(msj)
                    setError(msj)
                    console.error("Error guardando los datos. \n", response);
                }
            });

    }

    return (
        <div>
            <style>{'body { height: 100vh; background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
            {/* <img src={bottle} className={styles.imgBackground} /> */}
            <div className={styles.navCont} >
                <img onClick={() => { props.navigate('/messi') }} src={budweiser} className={styles.imgLogo} />
                <img onClick={() => window.history.back()} src={close} className={styles.closeIcon} />
            </div>
            <form onSubmit={check} style={{ marginTop: "3%" }}>
                <div className={styles.formContainer}>
                    <h3>Recuperá tu contraseña</h3>
                    {errorValidation.target == 'email' ? (<p>{errorValidation.msg}</p>) : null}
                    <input type='email' placeholder="ingresá tu email" onChange={e => setEmail(e.target.value)} required></input>
                    {message ? <p className={styles.mensaje}>{message}</p> : null}
                    <div className={styles.btnCont}>
                        <div className={styles.error}>
                            {error ? (<p>{error}</p>) : null}
                        </div>
                        <button className={styles.btnFirst} type='submit'>ENVIAR</button>
                        {/* <div className={styles.recover}><p>¿Ya tenés cuenta?</p>
                            <a onClick={() => {
                                if (location && location.survey) props.navigate('/messi644/login', { state: { survey: location.survey } })
                                else props.navigate('/messi644/login')
                            }}
                            >Iniciá sesión</a>
                        </div> */}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Forgot;