import React, { Fragment, useContext, useState } from 'react';
import { Link } from "gatsby"
import styles from '../scss/components/_Navbar.module.scss';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";

const Navbar = ({ props, logOut }) => {

    const state = useContext(GlobalStateContext)

    return (
        <div className={styles.navContainer}>
            <ul>
                <a href="https://www.budweiser.com.ar/producto/"><li>Nuestra birra</li></a>
                {/* <a href="https://www.budweiser.com.ar/futbol/"><li>Fútbol</li></a> */}
                <a href="https://www.budweiser.com.ar/sustentabilidad/"><li>Sustentabilidad</li></a>
                <a href="https://www.tada.com.ar/catalogo/grupo/budweiser"><li>Comprar</li></a>
                {/* <a onClick={() => { props.navigate('/messi644/home') }}><li>Messi 644</li></a> */}
                {state.user && state.user.logged ? (
                    <>
                    <a onClick={() => logOut()}><li>Cerrar sesión</li></a>
                    </>
                ):(
                    <>
                    <a onClick={() => { props.navigate('/messi644/login') }}><li>Iniciar sesión</li></a>
                    </>
                )}
            </ul>
        </div>
    )
}

export default Navbar