import React from 'react';
import styles from '../scss/components/_ModuloGol.module.scss';
import messiBkg from "../images/messi644/IlustracionMessi-01.png";
import pause from "../images/icons/Pausa.svg";


const ModuloGol = ({ misGoles, props, goles, goalCount }) => {
    return (
        <div className={styles.golesContainer}>
            {goles && misGoles.length ? (
                misGoles.map((gol, index) => {
                    return (
                        <div key={index} className={styles.golContainer} style={{ backgroundImage: `url(${messiBkg})`, opacity: !goles[index].Habilitado || goles[index].Pausa ? ".6" : "1" }}>
                            <div className={styles.titleCont}>
                                <h2>Messi<br /><span style={{ color: "#CF152D" }}>#{gol.idGoal}</span></h2>
                                {goles[index].Pausa ? (
                                    <div className={styles.finalizadoCont}>
                                        <img src={pause} />
                                        <p>Partido en curso</p>
                                    </div>) : (<></>)}
                            </div>
                            {gol.completed && goles[index].Habilitado ? (
                                <div className={styles.btnFirst}>COMPLETADO</div>
                            ) : (
                                    <>
                                        {goles[index].Habilitado ? (
                                            <>
                                                {goles[index].Pausa ? (
                                                    <div className={styles.btnThird} onClick={() => {
                                                    }}>PAUSADO</div>
                                                ) : (
                                                        <div className={styles.btnSecond} onClick={() => {
                                                            props.navigate('/messi644/quiz', { state: { goal: gol.idGoal, goals: misGoles, count: goalCount } })
                                                        }}>Comenzar</div>
                                                    )}
                                            </>
                                        ) : (
                                                <div className={styles.btnThird} >FINALIZADO</div>

                                            )}
                                    </>
                                )
                            }
                        </div>
                    )
                })
            ) : (null)}
        </div >
    )
}

export default ModuloGol;