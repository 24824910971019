import React from 'react';
import styles from '../scss/components/_Quiz.module.scss';
import check from "../images/icons/check.svg";
import back from "../images/icons/back.svg";

const Numeros = ({ index, setIndex, selected, handleBack, isMobile = false }) => {

    return (
        <div className={isMobile ? styles.currentQuestionContainer : styles.currentQuestionContainerDesk}>
            <div className={styles.currentQuestion} 
            // style={selected[4] ? ({ marginTop: "80px" }) : (null)}
            >
                <div className={index + 1 === 1 ? (styles.isNro) : (styles.notNro)}>
                    {index + 1 > 1 ? (<img src={check} />) : ("1")}
                </div>
                <div className={index + 1 === 2 ? (styles.isNro) : (styles.notNro)}>{index + 1 > 2 ? (<img src={check} />) : ("2")}
                </div>
                <div className={index + 1 === 3 ? (styles.isNro) : (styles.notNro)}>{index + 1 > 3 ? (<img src={check} />) : ("3")}
                </div>
                <div className={index + 1 === 4 ? (styles.isNro) : (styles.notNro)}>{index + 1 > 4 ? (<img src={check} />) : ("4")}
                </div>
                <div className={index + 1 === 5 ? (styles.isNro) : (styles.notNro)}>5</div>
            </div>
            {index + 1 > 1 ? (
                <div className={styles.goBack} onClick={() => handleBack()}>
                    <img src={back} />
                    <p>Volver</p>
                </div>) : (<></>)}
        </div>
    )
}

export default Numeros; 