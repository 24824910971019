import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_Quiz.module.scss';
import messiBkg from "../images/messi644/IlustracionMessi-01.png";
import SwipeableViews from 'react-swipeable-views';
import { CSSTransition } from 'react-transition-group';
import './effects.css'
import { GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import panel from "../images/icons/panel.svg"
import MessiFooter from "./MessiFooter"
import Numeros from "./Numeros"
import { IsMobileDevice } from '../utils/index';
import { animateScroll as scroll } from 'react-scroll';

const Quiz = ({ slider, props }) => {

    const state = useContext(GlobalStateContext)
    const [index, setIndex] = useState(0);
    const [qA, setQA] = useState(false);
    const [selected, setSelected] = useState([0, 0, 0, 0, 0]);
    const [effect, setEffect] = useState();
    const [user, setUser] = useState(false);
    const [nroGol, setNroGol] = useState(false);
    const [flag, setFlag] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false)
    const location = props.location.state

    useEffect(() => {
        setIsMobile(IsMobileDevice());
        if (!qA) {
            fetch('https://wabudweiser-prd.azurewebsites.net/q&a', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(async response => {
                    if (response.ok) {
                        let data = await response.json()
                        let pages = [
                            {
                                question: data.questions[0],
                                answers: [data.answers[0], data.answers[1], data.answers[2], data.answers[3]]
                            },
                            {
                                question: data.questions[1],
                                answers: [data.answers[4], data.answers[5], data.answers[6]]
                            },
                            {
                                question: data.questions[2],
                                answers: [data.answers[7], data.answers[8], data.answers[9], data.answers[10]]
                            },
                            {
                                question: data.questions[3],
                                answers: [data.answers[11], data.answers[12], data.answers[13], data.answers[14]]
                            },
                            {
                                question: data.questions[4],
                                answers: [data.answers[15], data.answers[16], data.answers[17], data.answers[18]]
                            }
                        ]
                        setQA(pages)
                        scroll.scrollTo(30)
                    } else {
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        }
        if (!user) {
            if (state.user) {
                if (state.user.logged) {
                    //Esta logueado y pregunto si viene desde Mi cuenta
                    if (location && location.goal) {
                        setUser(state.user)
                        setNroGol(location.goal)
                    } else {
                        props.navigate('/messi644/micuenta')
                    }
                } else {
                    let cookie = Cookies.get('bud')
                    if (cookie) {
                        props.navigate('/messi644/micuenta')
                    } else {
                        if (!flag) fetchGoal()
                        setUser(false)
                    }
                }
            } else {
                if (!flag) fetchGoal()
                setUser(false)
            }
        }
    }, [effect])

    const fetchGoal = () => {
        setFlag(true)
        fetch(`https://wabudweiser-prd.azurewebsites.net/goals`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].Habilitado && !data[i].Pausa) {
                            setNroGol(data[i].Gol)
                            break;
                        }
                    }
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    const handleAnswer = (question, ans) => {
        let newSel = selected
        switch (true) {
            case (ans <= 4):
                if(isMobile) scroll.scrollTo(78)
                newSel[0] = ans;
                break;
            case (ans <= 7):
                newSel[1] = ans;
                break;
            case (ans <= 11):
                newSel[2] = ans;
                break;
            case (ans <= 15):
                newSel[3] = ans;
                break;
            case (ans <= 19):
                newSel[4] = ans;
                break;
            default:
        }
        setSelected(newSel)
        setEffect(ans)
    }
    const handleBack = () => {
        setIndex(index - 1)
    }
    const handleGoal = () => {
        setLoading(true)
        let arr = []
        selected.map((ans, index) => {
            let obj = {}
            obj.question = index + 1
            obj.answer = ans
            arr.push(obj)
        })
        let survey = [nroGol, arr]
        if (user.logged) {
            fetch('https://wabudweiser-prd.azurewebsites.net/surveys', {
                method: 'POST',
                body: JSON.stringify(arr),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'id': user.user.idUser,
                    'goal': nroGol,
                }
            })
                .then(async response => {
                    if (response.ok) {
                        let data = await response.json()
                        setTimeout(() => {
                            if (nroGol == 644) props.navigate('/messi644/thankyou')
                            else props.navigate('/messi644/micuenta')
                        }, 1000);
                    } else {
                        let msj = await response.json()
                        setLoading(false)
                        props.navigate('/messi644/micuenta')
                    }
                });


        } else {
            props.navigate('register', { state: { survey: survey } })
        }
    }

    return (
        <div className={styles.superContainer}>
            <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 25%)}'}</style>
            {!user || location && !location.count ? (
                <>
                    <div className={styles.gestionar}>
                        <h3>Gestioná tus goles</h3>
                    </div>
                    <div className={styles.black}>
                    </div>
                </>
            ) : null}
            <img src={messiBkg} className={styles.imgBackground} />

            {nroGol ? (
                <>
                    <div className={styles.navCont} >
                        <img src={budweiser} onClick={() => { props.navigate('/messi644/home') }} className={styles.imgLogo} />
                        <img onClick={() => {
                            if (!user) {
                                props.navigate('/messi644/register')
                            } else props.navigate('/messi644/micuenta')
                        }} src={panel} className={styles.closeIcon} />
                    </div>
                    <div className={styles.titleCont}><h3>Gol</h3><h3>#{nroGol}</h3>
                        {isMobile ? null : <Numeros handleBack={handleBack} setIndex={setIndex} selected={selected} index={index} />}
                    </div>
                    <div className={styles.quizContainer}>
                        {qA ? (
                            <>
                                <div className={styles.questionContainer}><h3>{qA[index] ? qA[index].question.description.replace('XXX', nroGol) : null}</h3></div>
                                <SwipeableViews
                                    index={index}
                                    disabled={true}
                                    springConfig={{
                                        duration: "0.9s",
                                        easeFunction: "cubic-bezier(0.1, 0.35, 0.2, 1)",
                                        delay: "0.1s",
                                    }}
                                >
                                    <div>
                                        {qA[0].answers.map((rta, ind) => {
                                            return (
                                                <div key={ind}
                                                    onClick={() => {
                                                        handleAnswer(index + 1, rta.idAnswer)
                                                        if (index < 4) setIndex(index + 1)
                                                    }}
                                                    className={selected.find((element) => element == rta.idAnswer) ? styles.answerSelectedContainer : styles.answerContainer}>
                                                    <p>{rta.description}</p>
                                                    <p>{rta.value} ptos</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {qA[1].answers.map((rta, ind) => {
                                            return (
                                                <div
                                                    key={ind}
                                                    onClick={() => {
                                                        handleAnswer(index + 1, rta.idAnswer)
                                                        if (index < 4) setIndex(index + 1)
                                                    }}
                                                    className={selected.find((element) => element == rta.idAnswer) ? styles.answerSelectedContainer : styles.answerContainer}>
                                                    <p>{rta.description}</p>
                                                    <p>{rta.value} ptos</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {qA[2].answers.map((rta, ind) => {
                                            return (
                                                <div
                                                    key={ind}
                                                    onClick={() => {
                                                        handleAnswer(index + 1, rta.idAnswer)
                                                        if (index < 4) setIndex(index + 1)
                                                    }}
                                                    className={selected.find((element) => element == rta.idAnswer) ? styles.answerSelectedContainer : styles.answerContainer}>
                                                    <p>{rta.description}</p>
                                                    <p>{rta.value} ptos</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {qA[3].answers.map((rta, ind) => {
                                            return (
                                                <div
                                                    key={ind}
                                                    onClick={() => {
                                                        handleAnswer(index + 1, rta.idAnswer)
                                                        if (index < 4) setIndex(index + 1)
                                                    }}
                                                    className={selected.find((element) => element == rta.idAnswer) ? styles.answerSelectedContainer : styles.answerContainer}>
                                                    <p>{rta.description}</p>
                                                    <p>{rta.value} ptos</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        <div>
                                            {qA[4].answers.map((rta, ind) => {
                                                return (
                                                    <div
                                                        key={ind}
                                                        onClick={() => {
                                                            handleAnswer(index + 1, rta.idAnswer)
                                                        }}
                                                        className={selected.find((element) => element == rta.idAnswer) ? styles.answerSelectedContainer : styles.answerContainer}>
                                                        <p>{rta.description}</p>
                                                        <p>{rta.value} ptos</p>
                                                    </div>
                                                )
                                            })}
                                            {selected[4] ? (
                                                <div className={styles.finalizarContainer}>
                                                    <CSSTransition
                                                        in={true}
                                                        appear={true}
                                                        timeout={500}
                                                        classNames='fade'
                                                    >
                                                        {loading ? (<img style={{ width: '50px', height: 'auto', marginTop: '5px',marginBottom: '15px',marginLeft:'44%' }} src={require("../images/messi644/spinner.gif")} />) : (<div onClick={handleGoal} className={styles.btnFirst}>Finalizar GOL #{nroGol}</div>)}
                                                    </CSSTransition>
                                                </div>
                                            ) : (null)}
                                        </div>
                                    </div>
                                </SwipeableViews>
                            </>
                        ) : null}
                    </div>
                    {isMobile ? <Numeros handleBack={handleBack} isMobile={isMobile} setIndex={setIndex} selected={selected} index={index} /> : null}
                </>
            ) : (
                    
                    <div className={styles.awaitContainer}>
                        <div className={styles.await} ><h3 >ESPERA A QUE FINALICE EL PARTIDO PARA PODER PARTICIPAR</h3></div>
                    </div>
                )}
            {isMobile ? null : <MessiFooter />}
        </div>
    )
}

export default Quiz;