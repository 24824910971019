import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_TablaGeneral.module.scss';
import share from "../images/icons/material-share.svg";
import next from "../images/icons/arrow-right.svg";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import InvitarCodigo from './InvitarCodigo';
import { animateScroll as scroll } from 'react-scroll';


const TablaGeneral = ({ slider, isMobile, goalCount, activeT, props, usuario }) => {
    const state = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)
    const [leaderboard, setLeaderBoard] = useState(false);
    const [myTournaments, setMyTournaments] = useState(false);
    const [tournaments, setTournaments] = useState([]);
    const [user, setUser] = useState(usuario);
    const [idx, setIdx] = useState(0)
    const [toshare, setToshare] = useState(false)

    const location = props.location.state

    useEffect(() => {
        if (!user) {
            if (state.user) {
                if (state.user.logged) {
                    fetchTournaments(state.user.user.idUser)
                    setUser(state.user)
                }
            } else {
                let cookie = Cookies.get('bud')
                if (cookie) {
                    fetchTournaments(cookie)
                    setUser({ logged: true, user: { idUser: cookie } })
                    dispatch({ type: "SET_USER", user: { logged: true, user: { idUser: cookie } } })
                }
                else {
                    setUser(false)
                }
            }
        }
        if (!leaderboard) {
            fetch('https://wabudweiser-prd.azurewebsites.net/surveys', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(async response => {
                    if (response.ok) {
                        let data = await response.json()
                        setLeaderBoard(data)
                    } else {
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        }
        if (myTournaments) {
            myTournaments.map(tour => {
                fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/${tour.idTournament}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(async response => {
                        if (response.ok) {
                            let newT = tournaments
                            let data = await response.json()
                            data[0].adminName = tour.adminName
                            newT.push(data)
                            setTournaments(newT)
                            if (location && location.mitorneo) {
                                scroll.scrollTo(700)
                            }
                        } else {
                            console.error("Error guardando los datos. \n", response);
                        }
                    });
            })
        }
    }, [myTournaments])

    const fetchTournaments = (idUser) => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/findall/${idUser}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    if (data.length) setMyTournaments(data)
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    const handleIndex = () => {
        if (idx + 1 === tournaments.length) setIdx(0)
        else { setIdx(idx + 1) }
    }

    return (
        <div className={styles.tableContainer}>
            {activeT ? (
                <>
                    {tournaments.length ? (
                        <>
                            {toshare ? (
                                <InvitarCodigo props={props} setToshare={setToshare} showCode={tournaments[idx][0].Codigo} />
                            ) : (
                                    <div className={styles.headerMisTorneos}>
                                        <div>
                                            <h3>{tournaments[idx][0].Name}</h3>
                                            <p>Creado por {tournaments[idx][0].adminName.toUpperCase()}</p>
                                        </div>
                                        <div className={styles.btnContainer}>
                                            <div onClick={() => {
                                                if (navigator.share) {
                                                    navigator
                                                        .share({
                                                            title: `bud644`,
                                                            text: `Unite a mi torneo: ${tournaments[idx][0].Name.toUpperCase()}`,
                                                            url: "tournament/" + tournaments[idx][0].Codigo,
                                                        })
                                                        .then(() => {
                                                            console.log('Successfully shared');
                                                        })
                                                        .catch(error => {
                                                            console.error('Something went wrong sharing the blog', error);
                                                        });
                                                } else {
                                                    setToshare(true)
                                                }
                                            }} className={styles.btnShare}><img style={{ width: "14px" }} src={share} alt="compartir" /></div>


                                            <div onClick={handleIndex} className={styles.btnGenerales}><img style={{ width: "7px" }} src={next} alt="next" /></div>
                                        </div>
                                    </div>
                                )}
                        </>
                    ) : (null)}
                    {tournaments.length ? (
                        <div className={styles.tablaOverflow}>
                            <div className={styles.titlesTable}>
                                <p>Puesto</p>
                                <p style={{ marginLeft: "10px" }}>Nombre</p>
                                <p>Puntaje</p>
                            </div>
                            {tournaments[idx].map((person, index) => {
                                return (
                                    <div key={index} className={styles.tablaCont}>
                                        <div className={index % 2 === 0 ? styles.tablaContainer : styles.tablaContainerDark}>
                                            <h4>#{index + 1}</h4>
                                            <h3>{person.Nombre}</h3>
                                            <h3>{person.Puntaje}</h3>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                            <div className={styles.sinTorneo}>
                                <h1>Aún no tenés torneos</h1>
                                <p>Competí contra tus amigos y compará los resultados.</p>
                            </div>
                        )}
                </>
            ) : (<>
                <div className={styles.titlesTable}>
                    <p>Puesto</p>
                    <p style={{ marginLeft: "10px" }}>Nombre</p>
                    <p>Puntaje</p>
                </div>
                <div className={styles.tablaOverflow}>
                    {leaderboard ? (
                        leaderboard.map((person, index) => {
                            return (
                                <div key={index} className={styles.tablaCont}>
                                    <div className={index % 2 === 0 ? styles.tablaContainer : styles.tablaContainerDark}>
                                        <h4>#{index + 1}</h4>
                                        <h3>{person.Nombre}</h3>
                                        <h3>{person.Puntaje}</h3>
                                    </div>
                                </div>
                            )
                        })
                    ) : (<p style={{ color: "white", textAlign: "center" }}>Cargando...</p>)}
                </div>
            </>)}

            {/* {goalCount != 5 ? (
                activeT ? (
                    <div onClick={() => props.navigate('tournament')} className={styles.btnSecond}>Nuevo torneo</div>
                ) : (
                        <div onClick={() => props.navigate('quiz')} className={styles.btnSecond}>Empezar</div>
                    )
            ) : (
                    activeT ? <div onClick={() => props.navigate('tournament')} className={styles.btnSecond}>Nuevo torneo</div> : null
                )} */}
        </div>
    )

}

export default TablaGeneral;