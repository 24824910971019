import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_Login.module.scss';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Cookies from "js-cookie";
import close from "../images/icons/close.svg"
import budweiser from "../images/messi644/logoBudweiser-01.svg" 

const Login = (props) => {

    const dispatch = useContext(GlobalDispatchContext)
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);
    const [user, setUser] = useState(false)
    const [error, setError] = useState(false)

    const location = props.location.state

    const logIn = (e) => {
        e.preventDefault()
        Cookies.remove('bud')
        fetch('https://wabudweiser-prd.azurewebsites.net/users/login', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                if (response.status == 200) {
                    let data = await response.json()
                    Cookies.set('bud', data.user.idUser, { expires: 1 })
                    setUser(data)
                    dispatch({ type: "SET_USER", user: data })
                    if (location && location.survey) {
                        let arr = location.survey[1]
                        let nroGol = location.survey[0]
                        fetch('https://wabudweiser-prd.azurewebsites.net/surveys', {
                            method: 'POST',
                            body: JSON.stringify(arr),
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                                'id': data.user.idUser,
                                'goal': nroGol
                            }
                        })
                            .then(async response => {
                                if (response.ok) {
                                    let res = await response.json()
                                    if (nroGol == 644) props.navigate('/messi644/thankyou')
                                    else props.navigate('/messi644/micuenta')
                                } else {
                                    let msj = await response.json()
                                    console.log(msj)
                                    if (response.status === 409) props.navigate('/messi644/micuenta')
                                    console.error("Error guardando los datos. \n", response);
                                }
                            });
                    } else if (location && location.tournament) {
                        let userT = { idTournament: location.tournament, idUser: data.user.idUser }
                        fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/find/${userT.idTournament}`, {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(async response => {
                                console.log(response)
                                if (response.ok) {
                                    let data = await response.json()
                                    if (data.length) {
                                        let flag = true
                                        data.map(tour => {
                                            if (tour.idUser === userT.idUser) flag = false
                                        })
                                        if (flag){
                                            fetch('https://wabudweiser-prd.azurewebsites.net/tournaments/users', {
                                                     method: 'POST',
                                                     body: JSON.stringify(userT),
                                                     credentials: 'include',
                                                     headers: {
                                                         'Content-Type': 'application/json',
                                                     }
                                                 })
                                                     .then(async response => {
                                                         if (response.ok) {
                                                             setTimeout(() => {
                                                                 props.navigate('/messi644/micuenta', {state: {mitorneo: true}})
                                                             }, 1000);
                                                         } else {
                                                             let msj = await response.json()
                                                             console.log(msj)
                                                             console.error("Error uniendose al torneo. \n", response);
                                                             props.navigate('/messi644/home')
                                                         }
                                                     })
                                        }
                                        else { setError("YA ESTABAS UNIDO") }
                                    } else {
                                        setError("CÓDIGO INVALIDO")
                                    }
                                } else {
                                    console.error("Error guardando los datos. \n", response);
                                }
                            });
                    } else {
                        props.navigate('/messi644/micuenta')
                    }
                } else {
                    let msj = await response.text()
                    console.log(msj)
                    setError(msj)
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    return (
        <div>
            <style>{'body { background-color: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
            <div className={styles.navCont} >
                <img onClick={() => { props.navigate('/messi644/home') }} src={budweiser} className={styles.imgLogo} />
                <img onClick={() => window.history.back()} src={close} className={styles.closeIcon} />
            </div>
            <form onSubmit={logIn}>
                <div className={styles.formContainer}>
                    <h3>Iniciá sesión</h3>
                    <input type='text' name="usuario" placeholder="email" onChange={e => setEmail(e.target.value)} required></input>
                    <input type='password' name="contraseña" placeholder="Contraseña" onChange={e => setPassword(e.target.value)} required></input>
                    <div className={styles.recover} onClick={() => props.navigate("/messi644/forgot")}><p>¿Olvidaste tu contraseña?</p><a>Recuperar</a></div>
                    <div className={styles.btnCont}>
                        {error ? (<p className={styles.mensaje}>{error}</p>) : null}
                        <button className={styles.btnFirst} type='submit'>Ingresar</button>
                        <div className={styles.recover}><p>¿No tenés cuenta?</p><a onClick={() => {
                            if (location.survey) props.navigate('/messi644/register', { state: { survey: location.survey } })
                            else props.navigate('/messi644/register')
                        }}>Registrate</a></div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login;