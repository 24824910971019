import React, { useContext, useEffect, useState } from 'react';
import styles from '../scss/components/_Tournament.module.scss';
import close from "../images/icons/close.svg";
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import InvitarCodigo from "./InvitarCodigo"


const Tournament = ({ props }) => {

    const state = useContext(GlobalStateContext);
    const [create, setCreate] = useState(false);
    const [name, setName] = useState(false);
    const [code, setCode] = useState(false)
    const [showCode, setShowCode] = useState(0);
    const [error, setError] = useState(false)


    const handleClose = () => {
        window.history.back()
        // props.navigate("/messi644/micuenta")
    }

    const handleCode = () => {
        fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/find/${code}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                console.log(response)
                if (response.ok) {
                    let data = await response.json()
                    if (data.length) {
                        let flag = true
                        data.map(tour => {
                            if (tour.idUser === state.user.user.idUser) flag = false
                        })
                        if (flag) joinTournament()
                        else { setError("TORNEO YA AGREGADO") }
                    } else {
                        setError("CÓDIGO INVALIDO")
                    }
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }
    const checkIfExists = () => {
        if (!name) {
            setError("INGRESAR NOMBRE!")
        } else {
            let codigo = Math.floor(100000 + Math.random() * 900000)
            fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/find/${codigo}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(async response => {
                    console.log(response)
                    if (response.ok) {
                        let data = await response.json()
                        if (data.length) {
                            checkIfExists()
                        } else {
                            createTournament(codigo)
                        }
                    } else {
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        }
    }

    const createTournament = (codigo) => {
        let info = { idTournament: codigo, idUser: state.user.user.idUser, name: name }
        fetch('https://wabudweiser-prd.azurewebsites.net/tournaments', {
            method: 'POST',
            body: JSON.stringify(info),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(async response => {
                if (response.ok) {
                    let data = await response.json()
                    let userT = { idTournament: codigo, idUser: state.user.user.idUser }
                    fetch('https://wabudweiser-prd.azurewebsites.net/tournaments/users', {
                        method: 'POST',
                        body: JSON.stringify(userT),
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                        .then(async response => {
                            if (response.ok) {
                                setShowCode(codigo)
                            } else {
                                let msj = await response.json()
                                console.log(msj)
                                console.error("Error guardando los datos. \n", response);
                            }
                        })
                } else {
                    let msj = await response.json()
                    console.log(msj)
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }
    const joinTournament = () => {
        let userT = { idTournament: code, idUser: state.user.user.idUser }
        fetch('https://wabudweiser-prd.azurewebsites.net/tournaments/users', {
            method: 'POST',
            body: JSON.stringify(userT),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(async response => {
                if (response.ok) {
                    props.navigate('/messi644/micuenta')
                } else {
                    let msj = await response.json()
                    console.log(msj)
                    console.error("Error uniendose al torneo. \n", response);
                }
            })
    }

    return (
        <div>
            <style>{'body { background-color: #CF152D; }'}</style>
            <div className={styles.formContainer}>
                <img src={budweiser} className={styles.logo} />
                <div className={styles.zIndex}>
                    <img onClick={handleClose} src={close} alt="close" className={styles.close} />
                </div>
                <div className={styles.text}>
                    <h3>INVITÁ A TUS AMIGOS Y COMPARÁ RESULTADOS</h3>
                </div>
                {showCode ? (<InvitarCodigo showCode={showCode} props={props} />) : (null)}
                <input type='text' name="name" placeholder="NOMBRE DEL TORNEO" maxLength="15" onChange={e => setName(e.target.value)}></input>
                <p>*Hasta 15 caracteres</p>
                {showCode ? <h3 className={styles.text}>#{showCode}</h3> : null}
                <div className={styles.btnFirst} onClick={checkIfExists}>CREAR TORNEO</div>
                <p style={{ marginTop: '15px' }}>¿Te invitaron a un Torneo?</p>
                <h6>Ingresa el código para sumarte</h6>
                <input type='text' name="codigo" placeholder="CÓDIGO DEL TORNEO" onChange={e => setCode(e.target.value)}></input>
                <div className={styles.btnFirst} onClick={handleCode}>INGRESAR</div>
                {error ? <p style={{ fontSize: '20px' }}>{error}</p> : null}
            </div>
        </div>
    )
}

export default Tournament