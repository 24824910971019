import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from '../scss/components/_MiCuentaCodigo.module.scss';
import bottle from "../images/messi644/BotellaBud.png"
import budweiser from "../images/messi644/logoBudweiser-01.svg"
import close from "../images/icons/close.svg"
import {GlobalDispatchContext} from "../context/GlobalContextProvider";
import Cookies from "js-cookie";


const MiCuentaCodigo = (props) => {

    const dispatch = useContext(GlobalDispatchContext)
    const [name, setName] = useState(false)
    const [user, setUser] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!name) {
            fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/${props.id}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(async response => {
                    if (response.ok) {
                        let data = await response.json()
                        if (data.length) setName(data[0].Name)
                    } else {
                        console.error("Error guardando los datos. \n", response);
                    }
                });
        }
        if (!user) {
                let cookie = Cookies.get('bud')
                if (cookie) {
                    setUser({ logged: true, user: { idUser: cookie } })
                    dispatch({ type: "SET_USER", user: { logged: true, user: { idUser: cookie } } })
                }
                else {
                    setUser(false)
            }
        }
    }, [name]);

    const handleClose = () => {
        props.navigate("/messi644")
    }

    const checkIfExists = (userT)=>{
        fetch(`https://wabudweiser-prd.azurewebsites.net/tournaments/find/${userT.idTournament}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async response => {
                console.log(response)
                if (response.ok) {
                    let data = await response.json()
                    if (data.length) {
                        let flag = true
                        data.map(tour => {
                            if (tour.idUser === userT.idUser) flag = false
                        })
                        if (flag){
                            fetch('https://wabudweiser-prd.azurewebsites.net/tournaments/users', {
                                     method: 'POST',
                                     body: JSON.stringify(userT),
                                     credentials: 'include',
                                     headers: {
                                         'Content-Type': 'application/json',
                                     }
                                 })
                                     .then(async response => {
                                         if (response.ok) {
                                             setTimeout(() => {
                                                 props.navigate('/messi644/micuenta', {state: {mitorneo: true}})
                                             }, 1000);
                                         } else {
                                             let msj = await response.json()
                                             console.log(msj)
                                             console.error("Error uniendose al torneo. \n", response);
                                             props.navigate('/messi644/home')
                                         }
                                     })
                        }
                        else { setError("YA ESTABAS UNIDO") }
                    } else {
                        setError("CÓDIGO INVALIDO")
                    }
                } else {
                    console.error("Error guardando los datos. \n", response);
                }
            });
    }

    return (
        <div>
            {name ? (
                !user ? (
                    <>
                    <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
                    <div className={styles.thanksContainer}>
                        <div className={styles.zIndex}>
                            <img src={bottle} className={styles.imgBackground} />
                            <img onClick={handleClose} src={close} alt="close" className={styles.close} />
                            <img src={budweiser} className={styles.logo} alt="Budweiser" />
                            <h4>TE INVITARON AL TORNEO</h4>
                            <h3>{name}</h3>
                            <h5>TE INVITARON A PARTICIPAR DE<br />UN TORNEO.<br />REGISTRATE Y EMPEZÁ A PARTICIPAR</h5>
                            <div onClick={() => props.navigate('/messi644/register', { state: { tournament: props.id } })} className={styles.btnSecond}>CREAR MI CUENTA</div>
                            <p>¿Ya tenes cuenta?</p>
                            <h6>Iniciá sesión y participá del torneo.</h6>
                            <div onClick={() => props.navigate('/messi644/login', { state: { tournament: props.id } })} className={styles.btnFirst}>INICIAR SESIÓN</div>
                        </div>
                    </div>
                </>
                ):(
                    <>
                    <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
                    <div className={styles.thanksContainer}>
                        <div className={styles.zIndex}>
                            <img src={bottle} className={styles.imgBackground} />
                            <img onClick={handleClose} src={close} alt="close" className={styles.close} />
                            <img src={budweiser} className={styles.logo} alt="Budweiser" />
                            <h4>TE INVITARON AL TORNEO</h4>
                            <h3>{name}</h3>
                            <h5>TE INVITARON A PARTICIPAR DE<br />UN TORNEO.<br />REGISTRATE Y EMPEZÁ A PARTICIPAR</h5>
                            {error ? <p style={{ fontSize: '16px',color: 'white' }}>{error}</p> : null}
                            <div onClick={() => {
                                 let userT = { idTournament: props.id, idUser: user.user.idUser }
                                 checkIfExists(userT)
                                }} className={styles.btnSecond}>UNIRME</div>
                            <p>¿Ya tenes cuenta?</p>
                            <h6>Iniciá sesión y participá del torneo.</h6>
                            <div onClick={() => props.navigate('/messi644/micuenta', { state: { tournament: props.id } })} className={styles.btnFirst}>IR A MI CUENTA</div>
                        </div>
                    </div>
                </>
                )
                
            ) : (
                    <>
                        <style>{'body { background: #CF152D; background: linear-gradient(180deg, rgba(148,10,27,1) 0%, rgba(207,21,45,1) 20%)}'}</style>
                        <div className={styles.thanksContainer}>
                            <div className={styles.zIndex}>
                                <img src={bottle} className={styles.imgBackground} />
                                <img onClick={handleClose} src={close} alt="close" className={styles.close} />
                                <img src={budweiser} className={styles.logo} alt="Budweiser" />
                                <h5>NO ENCONTRAMOS NINGUN<br />TORNEO CON EL<br />NUMERO #{props.id}</h5>
                                <div onClick={() => props.navigate('/messi644/register')} className={styles.btnSecond}>CREAR MI CUENTA</div>
                                <p>¿Ya tenes cuenta?</p>
                                <h6>Iniciá sesión y participá del torneo.</h6>
                                <div onClick={() => props.navigate('/messi644/login')} className={styles.btnFirst}>INICIAR SESIÓN</div>
                            </div>
                        </div>
                    </>
                )}
        </div>
    )
}

export default MiCuentaCodigo